import React from "react";
import styled from "styled-components";
import {Helmet} from "react-helmet";
import { Link } from "gatsby";
import Layout, { Headline } from '../components/layout';
import CarouselPurchases from "../components/carousels/purchases";
import {ThreeUp} from "../components/layout";
import mq from "../styles/media-query";

const ChannelColumn = styled.div`

    text-align: center;
    
    margin-top: 2rem;
    
    b {
        font-size: 1.6rem;
        font-weight: normal;
    }
    
    .btn {
        background-color: var(--brandPrimary);
        width: 100%;
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.2;
        height: 4.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        
        ${mq.lg`
            height: 3rem;
        `}
        
        &:hover {
            background-color: var(--brandSecondary);
        }
    }
`;

export default function Error404() {
    return (
        <Layout hero={false}>
            <Helmet>
                <title>Direct Buyers : 404</title>
                <meta name="description" content="Direct Buyers is Southern California's #1 Home Buyer!" />
            </Helmet>

            <div className={`container`}>

                <Headline><h1>Hmmm... there seems to be an error</h1></Headline>

                <p>We do have extensive resources available for those looking to sell homes and properties in Southern California.</p>

                <ThreeUp className={`row justify-content-center`}>
                    <ChannelColumn className={`col-md-4`}>
                        <Link to="/faq" className={`btn btn-primary`}>Frequently Asked Questions</Link>
                    </ChannelColumn>
                    <ChannelColumn className={`col-md-4`}>
                        <Link to="/about-us" className={`btn btn-primary`}>Learn More About Us</Link>
                    </ChannelColumn>
                    <ChannelColumn className={`col-md-4`}>
                        <Link to="/get-a-cash-offer" className={`btn btn-primary`}>Get a Cash Offer</Link>
                    </ChannelColumn>
                </ThreeUp>

                <CarouselPurchases />
            </div>
        </Layout>
    );
}
